import React from 'react';
import Fade from 'react-reveal/Fade';
// import { Icon } from 'react-icons-kit';
// import { playCircle } from 'react-icons-kit/fa/playCircle';
// import { openModal, closeModal } from '@redq/reuse-modal';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  BannerImageMobile,
  BannerImageStyled,
  ButtonGroup,
  // VideoWrapper,
} from './banner.style';
import bannerImg from 'common/assets/image/image.jpeg';
import bannerImgMobile from 'common/assets/image/appCreative/availableThumb.png';

// close button for modal
// const CloseModalButton = () => (
//   <Button
//     className="modalCloseBtn"
//     variant="fab"
//     onClick={() => closeModal()}
//     icon={<i className="flaticon-plus-symbol" />}
//   />
// );

// const ModalContent = () => (
//   <VideoWrapper>
//     <iframe
//       title="Video"
//       src="https://www.youtube.com/embed/hW98BFnVCm8"
//       frameBorder="0"
//     />
//   </VideoWrapper>
// );

const Banner = () => {
  // modal handler
  // const handleVideoModal = () => {
  //   openModal({
  //     config: {
  //       className: 'video-modal',
  //       disableDragging: true,
  //       default: {
  //         width: 'auto',
  //         height: 'auto',
  //         x: 0,
  //         y: 0,
  //       },
  //     },
  //     component: ModalContent,
  //     componentProps: {},
  //     closeComponent: CloseModalButton,
  //     closeOnClickOutside: true,
  //   });
  // };
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="We are in the business of empowering your restaurant"
            />
          </Fade>
          <Fade up delay={200}>
            <Text content="Save 100% of your third party commission fees. Restaurants that have partnered with GrubX have saved over 20,000$ every year. Maintain your margins and guest data to save on commissions." />
          </Fade>
          <Fade up delay={300}>
            <ButtonGroup>
              <Button
                className="primary"
                onClick={() => (window.location = '/create-your-online-store')}
                title="Signup your restaurant now"
              />
              {/* <div onClick={handleVideoModal}>
                <Button
                  className="text"
                  variant="textButton"
                  icon={<Icon icon={playCircle} />}
                  iconPosition="left"
                  title="How it works"
                />
              </div> */}
            </ButtonGroup>
          </Fade>
        </BannerContent>
        <BannerImage>
          <BannerImageStyled src={bannerImg} alt="Banner" />
        </BannerImage>
        <BannerImageMobile>
          <Image src={bannerImg} alt="Mobile Banner" />
        </BannerImageMobile>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
