import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import SectionWrapper, { ThumbWrapper, TextWrapper, NewFeaturesContainer, DineInItem } from './chooseUs.style';
import deliveryPartner from 'common/assets/image/delivery-partner.jpeg'
import foodPackage from 'common/assets/image/food-package.png'
import dineIn from 'common/assets/image/dine-in.png'
import boxMan from 'common/assets/image/box-man.jpeg'
import { ButtonWrap, ContentWrap } from '../Pricing/pricing.style';


const ChooseUs = () => {
  const data = useStaticQuery(graphql`
    query {
      appCreativeJson {
        chooseUs {
          title
          thumb {
            publicURL
          }
          features {
            id
            title
            description
          }
        }
      }
    }
  `);

  const { thumb, features } = data.appCreativeJson.chooseUs;
  return (
    <SectionWrapper id="partners">
      <Container>
        <NewFeaturesContainer id="ordering">
          <DineInItem>
            <img src={foodPackage} />
            <h2>GrubX Take-out</h2>
            <h3>Our off-premise solutions</h3>
            <p>100% Contactless self-order and payment for pickup or delivery.</p>
          </DineInItem>
          <DineInItem>
            <img src={dineIn} />
            <h2>GrubX Dine-in</h2>
            <h3>Our off-premise solutions</h3>
            <p>Self-service + server supported order to table and contactless payment.</p>
          </DineInItem>
        </NewFeaturesContainer>
        
        <Button
            className="btn-book-now book-btn-grubx"
            onClick={() => window.location.href = "https://calendly.com/alenieto/30min"}
            title="Want to Learn more?"
          />
        
      </Container>
    </SectionWrapper>
  );
};

export default ChooseUs;
