import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';
import partner from '../../../common/assets/image/banner_1.png';
import banner2 from '../../../common/assets/image/banner_2.png';

const SectionWrapper = styled.section`
  padding: 75px 0 40px;
  @media only screen and (max-width: 1219px) {
    padding-bottom: 70px;
  }
  @media only screen and (max-width: 991px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 624px) {
    padding: 45px 0 60px;
  }
  > div.container {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (min-width: 1367px) {
      max-width: 1290px;
    }
    @media only screen and (max-width: 624px) {
      
    }
  }

.gradient-bg-container {
  z-index: 0;
  background-image: url(${partner});
  background-size: cover;
  padding: 68px 60px;
  margin: 0px 0px;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
  border-radius: 10px;
  position: relative;

  h3 {
    font-size: 30px;
  }

  h3, p {
    color: #FFF;
  }

  button {
    background-color: #FFF;
    border-radius: 10px;
  }
  span {
    color: #000;
  }
}

.second-bg {
  background-image: url(${banner2});
  margin-top: 10rem;
}

.btn-book-now:hover {
  transform: scale(1.05); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.mask {
  z-index: -1;
  background: rgb(0,0,0);
  background: -moz-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(7,69,15,1) 97%);
  background: -webkit-linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(7,69,15,1) 97%);
  background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(7,69,15,1) 97%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#07450F",GradientType=1);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  border-radius: 5px;
}

.driver-img-container {
  position: relative;
  display: flex;
}

.driver-img {
  align-self: center;
  width: 50%;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
  
}

.driver-img-1 {
  height: 300px;
  object-fit: cover;
}

.driver-img-2 {
  width: 500px;
  right: 50px;
  top: 60px;
}

@media only screen and (max-width: 624px) {
  .driver-img {
    display: none;
  }
}


@media only screen and (max-width: 972px) {
  .driver-img-2 {
    display: none;
  }
}

`;

export const ThumbWrapper = styled.div`
  width: calc(100% - 510px);
  @media only screen and (max-width: 1219px) {
    width: calc(100% - 425px);
  }
  @media only screen and (max-width: 991px) {
    width: calc(100% - 350px);
  }
  @media only screen and (max-width: 624px) {
    width: 80%;
    margin-top: 40px;
    margin-bottom: -35px;
  }
`;

export const TextWrapper = styled.div`
  width: 440px;
  margin-top: 15px;
  @media only screen and (max-width: 1219px) {
    margin-top: 0;
    width: 425px;
  }
  @media only screen and (max-width: 991px) {
    width: 350px;
  }
  @media only screen and (max-width: 624px) {
    width: 100%;
  }
  h2 {
    color: ${themeGet('colors.headingColor', '#000000')};
    font-size: 36px;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: -0.5px;
    margin-bottom: 45px;
    @media only screen and (max-width: 1219px) {
      font-size: 30px;
      margin-bottom: 15px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 26px;
      line-height: 1.4;
    }
  }
  p {
    color: ${themeGet('colors.textColor', 'rgba(52, 61, 72, 0.8)')};
    font-size: 15px;
    line-height: 28px;
    margin-bottom: 0;
  }
  .feature__block {
    margin-top: 40px;
    align-items: center;
    padding-right: 40px;
    @media only screen and (max-width: 991px) {
      padding-right: 0;
    }
    h3 {
      color: ${themeGet('colors.headingColor', '#000000')};
      font-size: 17px;
      line-height: 28px;
      font-weight: 700;
      margin-bottom: 10px;
      @media only screen and (max-width: 1366px) {
        line-height: 26px;
        margin-bottom: 7px;
      }
    }
    .icon__wrapper span {
      color: ${rgba('#09131F', 0.2)};
      font-size: 64px;
      margin-right: 30px;
      line-height: 1em;
      font-family: 'Work Sans', sans-serif;
      @media only screen and (max-width: 991px) {
        font-size: 52px;
        margin-right: 20px;
      }
    }
  }
`;

export default SectionWrapper;
