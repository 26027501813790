import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import FeatureBlock from 'common/components/FeatureBlock';
import { SectionHeader } from '../appCreative.style';
import { SectionWrapper, FeatureWrapper } from './keyFeatures.style';
import partner1 from '../../../common/assets/image/partner-1.png';
import partner2 from '../../../common/assets/image/partner-2.png';
import partner3 from '../../../common/assets/image/partner-3.png';
import partner4 from '../../../common/assets/image/partner-4.png';

const KeyFeatures = () => {
  const data = useStaticQuery(graphql`
    query {
      appCreativeJson {
        keyFeatures {
          title
          slogan
          features {
            id
            icon {
              publicURL
            }
            title
            description
          }
        }
      }
    }
  `);

  const { features } = data.appCreativeJson.keyFeatures;

  return (
    <SectionWrapper id="about">
      <Container>
        <SectionHeader>
          <Fade up>
            <Heading content={'Always available'} />
            <Text
              content={
                'We give you everything you need to start taking orders online.'
              }
            />
          </Fade>
        </SectionHeader>
        <FeatureWrapper>
          {features.map((item) => (
            <Fade up delay={100 * item.id} key={`key-feature--key${item.id}`}>
              <FeatureBlock
                icon={
                  <Fragment>
                    <Image
                      className="icon-key-feature"
                      src={item.icon.publicURL}
                      alt={item.title}
                    />
                  </Fragment>
                }
                title={<Heading as="h3" content={item.title} />}
                description={<Text content={item.description} />}
              />
            </Fade>
          ))}
        </FeatureWrapper>
        <div className="good-company-container">
            <Heading style={{textAlign: 'center'}} content={'You are in good company'} />
          <br />
          <br />
          <div className="partner-img-container">
            <div className="partner-div">
              <img className="partner-img" alt="partner-img-1" src={partner1} />
              <p>Taj Grill</p>
            </div>
            <div className="partner-div">
              <img className="partner-img" alt="partner-img-2" src={partner2} />
              <p>Daddio's Pizza</p>
            </div>
            <div className="partner-div">
              <img className="partner-img" alt="partner-img-3" src={partner3} />
              <p>Chung Chun Rice Dog</p>
            </div>
            <div className="partner-div">
              <img className="partner-img" alt="partner-img-4" src={partner4} />
              <p>Pig Out BBQ</p>
            </div>
          </div>
        </div>
      </Container>
    </SectionWrapper>
  );
};

export default KeyFeatures;
