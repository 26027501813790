import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { openModal, closeModal } from '@redq/reuse-modal';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import { SectionHeader } from '../appCreative.style';
import FeatureBlock from 'common/components/FeatureBlock';

import SectionWrapper, {
  ExperienceMainWrap,
  ExperienceWrapper,
  VideoWrapper,
  // VideoArea,
  // ClientWrapper,
  // ImageSlider,
  // ImageSlide,
} from './experience.style';

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
);

const Experiences = () => {
  const data = useStaticQuery(graphql`
    query {
      appCreativeJson {
        experiences {
          title
          slogan
          video_theme {
            publicURL
          }
          features {
            id
            icon {
              publicURL
            }
            title
            description
          }
          pos_software_includes {
            id
            icon {
              publicURL
            }
            title
          }
          features {
            id
            icon {
              publicURL
            }
            title
            description
          }
          clients {
            id
            logo {
              publicURL
            }
            name
            link
          }
        }
      }
    }
  `);

  const { slogan, pos_software_includes, features, video_theme, clients } =
    data.appCreativeJson.experiences;

  // modal handler
  const handleVideoModal = () => {
    openModal({
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: 'auto',
          height: 'auto',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    });
  };

  return (
    <SectionWrapper id="experience">
      <Container className="pos-container">
        <SectionHeader>
          <Heading content={'POS Software Plans Includes'} />
        </SectionHeader>
        <ExperienceMainWrap>
          <ExperienceWrapper>
            {pos_software_includes.map((item, index) => (
              <FeatureBlock
                key={`post_key-${index}`}
                id={`post_id-${item.id}`}
                className="experience__item"
                icon={
                  <Image
                    src={item.icon.publicURL}
                    alt={`Icon ${item.id}`}
                    objectFit="cover"
                    className="experience__image"
                  />
                }
                iconPosition="left"
                title={<Heading as="h4" className="pos_item_title" content={item.title} />}
                description={<Text content={item.description} />}
              />
            ))}
          </ExperienceWrapper>
        </ExperienceMainWrap>

        <Button onClick={() => window.location.href = "https://calendly.com/alenieto/30min"} className="book-demo-btn" title="Book your demo now!" />
      </Container>

      <br />
      <br />
      <br />
      <Container>
        <SectionHeader>
          <Heading content={'More features coming'} />
          <Text
            content={
              "When you're ready to go further you can purchase additional add-ons for fixed subscription fees."
            }
          />
        </SectionHeader>
        <ExperienceMainWrap>
          {/* <VideoArea onClick={handleVideoModal}>
            <img src={video_theme.publicURL} alt="Microsoft" />
            <Button
              className="video__btn"
              icon={<Icon className="plus" icon={ic_play_arrow} />}
            />
          </VideoArea> */}
          <ExperienceWrapper>
            {features.map((item, index) => (
              <FeatureBlock
                key={`post_key-${index}`}
                id={`post_id-${item.id}`}
                className="experience__item"
                icon={
                  <Image
                    src={item.icon.publicURL}
                    alt={`Icon ${item.id}`}
                    objectFit="cover"
                    className="experience__image"
                  />
                }
                iconPosition="left"
                title={<Heading as="h4" content={item.title} />}
                description={<Text content={item.description} />}
              />
            ))}
          </ExperienceWrapper>
        </ExperienceMainWrap>
        {/* <ClientWrapper>
          <div className="client__text">
            <Text as="span" content={'Companies who worked with us proudly'} />
          </div>
          <ImageSlider>
            <ImageSlide>
              {clients.map((item) => (
                <Logo
                  key={`slide1__key${item.id}`}
                  href={item.link}
                  logoSrc={item.logo.publicURL}
                  title={item.name}
                />
              ))}
            </ImageSlide>
            <ImageSlide>
              {clients.map((item) => (
                <Logo
                  key={`slide2__key${item.id}`}
                  href={item.link}
                  logoSrc={item.logo.publicURL}
                  title={item.name}
                />
              ))}
            </ImageSlide>
          </ImageSlider>
        </ClientWrapper> */}
      </Container>
    </SectionWrapper>
  );
};

export default Experiences;
